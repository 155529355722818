import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ContactUsForm from "./../components/ContactUsForm";
import MiscSocialSmall from "../components/icons/MiscSocialSmall";
// import ContactUsMap from "./../components/ContactUsMap";
import Layout from "./../components/Layout";
import StandalonePageLayout, {
  WhiteBox,
} from "./../components/StandalonePageLayout";

const DemoTestpage = () => {
  
  return (
    <Layout
      backgroundColor={"var(--green)"}
      headerColor={"var(--black)"}
      headerBlack
    >
      <StandalonePageLayout>
        <div>
            hellow
        </div>
      </StandalonePageLayout>
    </Layout>
  );
};

export default DemoTestpage;
